/* General Styles */
.hero {
  position: relative;
  height: 50vh;
  background-color: #333;
  overflow: hidden;
}

.slick-dots {
  bottom: 20px;
}

.slick-dots li button:before {
  color: white;
}

.carousel-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image covers the container while maintaining aspect ratio */
}

.buy-now-button {
  position: absolute;
  bottom: 20px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.buy-now-button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .carousel-image {
    height: 50vh;
  }

  .buy-now-button {
    font-size: 1.2em;
    padding: 15px 30px;
  }
}

@media (max-width: 768px) {
  .carousel-image {
    height: 50vh;
  }

  .buy-now-button {
    font-size: 1em;
    padding: 10px 20px;
  }
}
