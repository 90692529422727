/* src/components/BlogDetailPage.css */
.blog-detail {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .breadcrumb {
    margin-bottom: 20px;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #0f3460;
  }
  
  .breadcrumb a:hover {
    text-decoration: underline;
  }
  
  .blog-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .blog-detail h1 {
    margin-bottom: 20px;
  }
  
  .blog-detail p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  