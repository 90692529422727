/* src/components/BlogList.css */
.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  /* background-color: #1a1a2e; Adjusted background color */
}

.blog-tile {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  outline: 2px solid #0f3460; /* Outline color */
  padding: 20px; /* Added padding */
  color: #ffffff; /* Font color for text */
}

.blog-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.blog-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 2px solid #0f3460; /* Border color */
  margin-bottom: 15px;
}

.blog-tile h3 {
  font-size: 1.4em;
  margin: 0 0 10px 0;
  color: #e94560; /* Adjusted title color */
}

.blog-tile p {
  font-size: 1em;
  margin: 0 0 15px 0;
  color: #ffffff; /* Adjusted text color */
}

.read-more-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #e94560; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.2s;
}

.read-more-button:hover {
  background-color: #f05454; /* Button hover color */
}
