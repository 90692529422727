/* Pricing.css */

.pricing-section {
    padding: 60px 20px; /* Add padding to the top and bottom */
    background-color: #f4f4f4;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background-color: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 300px;
    text-align: center;
  }
  
  .pricing-card h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
  }
  
  .price {
    font-size: 2em;
    margin: 20px 0;
    color: #007bff;
  }
  
  .pricing-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .pricing-card li {
    margin-bottom: 10px;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  