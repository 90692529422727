/* FloatingWhatsAppIcon.css */

.floating-whatsapp {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25D366;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.floating-whatsapp:hover {
    transform: scale(1.1);
}

.floating-whatsapp img {
    width: 50%;
    height: 50%;
}

.floating-whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 24px;
}
  
.floating-whatsapp-icon:hover {
    background-color: #128c7e;
}

@media (max-width: 300px) {
    .floating-whatsapp,
    .floating-whatsapp-icon {
        width: 50px;
        height: 50px;
        bottom: 10px;
        right: 10px;
    }
}

/* Apply box-sizing to all elements */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove horizontal overflow */
html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}
