/* frontend/src/Register.css */
.register-page {
    display: flex;
    height: 100vh;
  }
  
  .promo-section {
    flex: 1;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-align: center;
  }
  
  .promo-content {
    max-width: 500px;
  }
  
  .promo-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .promo-content p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #666;
  }
  
  .form-section {
    flex: 1;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .register-container {
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .register-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .register-form input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .register-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .social-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .social-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .social-button.apple {
    background-color: #000;
    color: white;
  }
  
  .social-button.facebook {
    background-color: #4267b2;
    color: white;
  }
  
  .social-button.google {
    background-color: #db4437;
    color: white;
  }
  
  .divider {
    margin: 20px 0;
    color: #888;
    font-size: 14px;
  }
  
  .message {
    margin-top: 20px;
    color: red;
  }
  
  .login-link, .forgot-link {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .login-link a, .forgot-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .login-link a:hover, .forgot-link a:hover {
    text-decoration: underline;
  }
  