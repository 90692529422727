/* Testimonial.css */

.testimonial-section {
    padding: 60px 20px;
    text-align: center;
    background-color: #f4f4f4;
  }
  
  .testimonial-section h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  .slick-slider {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .testimonial {
    padding: 20px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .testimonial:hover {
    transform: translateY(-10px);
  }
  
  .quote {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .author {
    font-size: 1em;
    color: #333;
  }
  