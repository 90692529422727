.tutor-showcase-section {
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.tutor-showcase-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
}

.subject-tutors {
  margin-bottom: 40px;
}

.subject-tutors h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #444;
}

.tutor-profiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tutor-profile {
  padding: 20px;
  margin: 10px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 200px;
  text-align: center; /* Center text in profile */
  transition: transform 0.3s ease;
}

.tutor-profile:hover {
  transform: translateY(-10px);
}

.img-container {
  width: 100%;
  height: 150px; /* Adjust height as needed */
  position: relative;
  margin-bottom: 15px;
  overflow: hidden; /* Ensure images don't overflow */
  border-radius: 50%; /* Ensures avatars are round */
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the container */
}

.tutor-profile h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.tutor-profile p {
  font-size: 1em;
  color: #666;
}
