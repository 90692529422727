/* HowItWorks.css */

.how-it-works {
    padding: 60px 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .how-it-works h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  .steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .step {
    width: 200px;
    margin: 20px;
    padding: 20px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .step:hover {
    transform: translateY(-10px);
  }
  
  .step-icon {
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    background: #1e90ff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.5em;
  }
  
  .step h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .step p {
    color: #666;
  }
  