/* NewsletterSignup.css */

.newsletter {
    padding: 60px 20px;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .newsletter h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  .newsletter form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .newsletter input[type="email"] {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .newsletter button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .newsletter button[type="submit"]:hover {
    background-color: #0056b3;
  }
  