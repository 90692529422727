/* App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #1e90ff;
    color: white;
  }
  
  .nav ul {
    display: flex;
    list-style: none;
  }
  
  .nav li {
    margin: 0 15px;
  }
  
  .cta-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    background: white;
    color: #1e90ff;
    border: none;
    cursor: pointer;
  }
  
  .hero {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .hero-video {
    width: 100%;
    height: auto;
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .cta-button {
    padding: 15px 30px;
    background: #ff4500;
    border: none;
    color: white;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .key-s, .courses, .how-it-works, .testimonials, .tutors, .blog, .newsletter {
    padding: 60px 20px;
    text-align: center;
  }
  
 .imgDiv {
   width:100px;
   height:100px;
  }
  .benefits, .course-categories, .steps, .testimonial, .tutor-profiles, .blog-posts {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .benefit, .course-category, .step, .tutor-profile, .blog-post {
    margin: 20px;
    max-width: 300px;
  }
  
  .newsletter form {
    display: flex;
  }  