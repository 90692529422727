/* src/components/Footer.css */
.footer {
  background-color: #2c3e50;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  display: inline;
  margin: 0 10px;
}

.footer-links ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #007bff;
}

.contact-info {
  margin-top: 20px;
}

.copyright {
  margin-top: 10px;
  font-size: 14px;
}
