.contact-us-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .contact-us-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center form elements */
  }
  
  .contact-us-form label {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
    width: 100%; /* Ensure labels take full width */
  }
  
  .contact-us-form input[type="text"],
  .contact-us-form input[type="email"],
  .contact-us-form input[type="tel"],
  .contact-us-form textarea {
    width: calc(100% - 30px); /* Adjust width to leave space for padding */
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .contact-us-form textarea {
    resize: vertical;
    height: 100px;
  }
  
  .contact-us-submit-button,
  .contact-us-close-button {
    width: 100%; /* Make buttons take full width */
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 0; /* Adjust padding */
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .contact-us-submit-button:hover,
  .contact-us-close-button:hover {
    background-color: #45a049;
  }
  