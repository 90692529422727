.blog-post-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post-detail h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-post-detail .date {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 20px;
  }
  
  .blog-post-detail .content h2 {
    font-size: 1.8em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .blog-post-detail .content p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #444;
    margin-bottom: 15px;
  }
  
  .blog-post-detail .content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .blog-post-detail .content ul li {
    font-size: 1.1em;
    color: #444;
    margin-bottom: 5px;
  }
  