/* Ribbon.css */

.ribbon-container {
    width: 100%;
    background-color: #f8d7da; /* Light red background color */
    color: #721c24; /* Dark red text color */
    text-align: center;
    padding: 10px 0;
  }
  
  .ribbon {
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
    width: 90%;
    max-width: 800px; /* Adjust to your preference */
    margin: 0 auto;
    padding: 10px;
    background-color: #f8d7da; /* Light red background color */
    color: #721c24; /* Dark red text color */
    border: 1px solid #f5c6cb; /* Dark red border color */
    border-radius: 4px;
  }
  
  .ribbon:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    border-top-color: #f8d7da; /* Light red background color */
    border-width: 10px;
    margin-left: -10px;
  }
  
  .ribbon:before, .ribbon {
    display: inline-block;
    vertical-align: middle;
  }
  
  @media (max-width: 768px) {
    .ribbon {
      font-size: 1rem;
    }
  }
  