/* General Styles */
.services {
  padding: 60px 20px;
  text-align: center;
  background-color: #f4f4f4;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.services-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.service-card {
  position: relative;
  width: 300px;
  height: 400px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px;
  transition: transform 0.3s;
  clip-path: polygon(
    50% 0%,
    93% 25%,
    93% 75%,
    50% 100%,
    7% 75%,
    7% 25%
  );
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.service-icon {
  font-size: 3em;
  margin-bottom: 20px;
}

.service-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.service-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.service-button {
  background-color: black;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: .8em;
  text-transform: uppercase;
  transition: background-color 0.3s;
  text-decoration: none;
}

.service-button:hover {
  background-color: #0056b3;
}

/* Icon Colors */
.service-card:nth-child(1) .service-icon {
  color: #007bff; /* Blue for Website Hosting */
}

.service-card:nth-child(2) .service-icon {
  color: #28a745; /* Green for Application Development */
}

.service-card:nth-child(3) .service-icon {
  color: #ffc107; /* Yellow for Custom Software */
}

/* Responsive Styles */
@media (max-width: 992px) {
  .service-card {
    width: calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .service-card {
    width: calc(100% - 40px);
  }
}
