/* General Styles */
.header {
  background-color: white;
  color: #fff;
  padding: 20px 0;
}
.link-hover {
  cursor: pointer;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.logo-container {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.logo-image {
  height: 90px;
  margin-right: 10px;
}

.logo {
  font-family: Arial, sans-serif;
  font-size: 36px;
  font-weight: bold;
  color:#b7b2b2;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 10px;
}

.start-trial-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

/* Navigation Styles */
.nav-desktop {
  display: flex;
}

.nav-desktop ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-desktop ul li {
  margin-right: 20px;
}

.nav-desktop ul li a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease;
  font-weight: 600;
}

.nav-desktop ul li a:hover {
  font-weight: 700;
}

/* Mobile Styles */
.nav-mobile {
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.nav-mobile.open {
  display: flex;
}

.nav-mobile .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: white;
}

.nav-mobile ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-mobile ul li {
  margin: 10px 0;
}

.nav-mobile ul li a {
  text-decoration: none;
  color: white;
  font-size: 1.5em;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    /* margin-right: 20px; */
  }

  .logo-container {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  .nav-desktop {
    display: none;
  }

  .logo {
    font-size: 20px; /* Reduced font size for mobile screens */
    padding: 5px 10px; /* Adjust padding for mobile screens */
    border: 1px solid black; /* Adjust border for mobile screens */
  }

  .logo-image {
    height: 90px;
  }

  .start-trial-button {
    font-size: 0.8em; /* Reduced font size for mobile screens */
    padding: 8px 10px; /* Adjust padding for mobile screens */
  }

  .cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px; /* Add margin-top for spacing on mobile screens */
  }
}
