/* src/components/SocialMedia.css */
.social-media {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .social-media a {
    color: #ffffff; /* Adjust color as needed */
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: #007bff; /* Adjust hover color as needed */
  }
  