/* src/components/BlogPage.css */
.blog-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100vh; /* Full height of the viewport */
  box-sizing: border-box;
}

.blog-list-container {
  flex: 3;
  overflow-y: auto; /* Allow scrolling if content overflows */
  max-height: calc(100vh - 120px); /* Adjust height to account for padding and other elements */
  margin-bottom: 20px;
}

.search-bar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky;
  top: 20px;
  overflow-y: auto; /* Allow scrolling if content overflows */
  max-height: calc(100vh - 40px);
}

.search-bar {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 2px solid #0f3460;
  border-radius: 4px;
  outline: none;
  margin-bottom: 20px;
}

.category-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-list li {
  padding: 10px;
  cursor: pointer;
  /* color: #e94560; */
  color: black;
  transition: background-color 0.2s;
}

.category-list li:hover {
  /* background-color: #b7b2b2; */
  background-color: black;
  color: #ffffff;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  background: #16213e;
  border: 1px solid #0f3460;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination-button.active {
  background-color: #e94560;
}

.pagination-button:hover {
  background-color: #f05454;
}

@media (min-width: 768px) {
  .blog-page {
    flex-direction: row;
  }

  .blog-list-container {
    margin-bottom: 0;
  }
}
