/* General Styles */
.courses {
  padding: 60px 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.courses-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 25px;
}

.tab.active {
  background-color: black;
  color: #fff;
}

.tab:hover {
  background-color: #007bff;
  color: #fff;
}

.course-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.course-card, .resource-card {
  width: 300px;
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.course-card:hover, .resource-card:hover {
  transform: translateY(-10px);
}

.course-image, .resource-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.course-title, .resource-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.course-description, .resource-description {
  font-size: 1em;
  margin-bottom: 15px;
}

.enroll-button, .download-button {
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enroll-button:hover, .download-button:hover {
  background-color: black;
}

/* Icon Styles */
.course-icon {
  font-size: 3em; /* Increase the size of the icons */
  margin-bottom: 10px;
}

.course-card .fa-paint-brush {
  color: #f39c12;
}

.course-card .fa-mobile-alt {
  color: #3498db;
}

.course-card .fa-robot {
  color: #9b59b6;
}

.course-card .fa-cloud {
  color: #1abc9c;
}

.course-card .fa-database {
  color: #e74c3c;
}

.resource-card .fa-book {
  color: #f39c12;
}

.resource-card .fa-flask {
  color: #3498db;
}

.resource-card .fa-language {
  color: #9b59b6;
}

.resource-card .fa-globe {
  color: #1abc9c;
}

.resource-card .fa-book-open {
  color: #e74c3c;
}

.resource-card .fa-file-alt {
  color: #2ecc71;
}

.resource-card .fa-landmark {
  color: #e67e22;
}

.resource-card .fa-globe-americas {
  color: #8e44ad;
}

.resource-card .fa-balance-scale {
  color: #e74c3c;
}

.resource-card .fa-atom {
  color: #2ecc71;
}

.resource-card .fa-vials {
  color: #f39c12;
}

/* Icon Styles */
.course-icon {
  font-size: 3em; /* Increase the size of the icons */
  margin-bottom: 10px;
}

.free-course .fa-laptop-code {
  color: #f39c12; /* Example color for Tech & Software */
}

.free-course .fa-mobile-alt {
  color: #3498db; /* Example color for Mobile Apps Development */
}

.free-course .fa-robot {
  color: #9b59b6; /* Example color for AI/ML Integration */
}

.free-course .fa-cloud {
  color: #1abc9c; /* Example color for Cloud Hosting */
}

.free-course .fa-database {
  color: #e74c3c; /* Example color for Database Design */
}

.paid-course .fa-comments {
  color: #f39c12; /* Example color for Public Speaking */
}

.paid-course .fa-chess {
  color: #3498db; /* Example color for Chess */
}

.paid-course .fa-cube {
  color: #9b59b6; /* Example color for Rubik's Cube */
}

.paid-course .fa-pencil-alt {
  color: #1abc9c; /* Example color for Sketching */
}

.resource-card .fa-book {
  color: #f39c12; /* Example color for Mathematics */
}

.resource-card .fa-flask {
  color: #3498db; /* Example color for Science */
}

.resource-card .fa-language {
  color: #9b59b6; /* Example color for Sanskrit */
}

.resource-card .fa-globe {
  color: #1abc9c; /* Example color for Urdu */
}

.resource-card .fa-file-alt {
  color: #e74c3c; /* Example color for Hindi */
}

/* Add other resource icon styles similarly */

.enroll-button, .download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline */
}

.enroll-button:hover, .download-button:hover {
  background-color: #007bff;
}

@media (max-width: 768px) {

  
.tab {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: .9em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 25px;
}
 
}