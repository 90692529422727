/* BlogSection.css */

.blog {
    background-color: #f9f9f9;
    padding: 60px 0;
  }
  
  .container1 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .blog h2 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  .blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .blog-post {
    margin: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 300px;
    overflow: hidden;
  }
  
  .blog-post img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .post-content {
    padding: 20px;
  }
  
  .post-content h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .post-meta {
    color: #666;
    margin-bottom: 15px;
  }
  
  .post-content p {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .post-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .post-content button:hover {
    background-color: #0056b3;
  }
  