/* AboutUs.css */

.about-section {
    padding: 60px 20px;
    background-color: #f4f4f4;
    flex-direction: column
  }
  
  .container2 {
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #333;
  }
  
  h3 {
    font-size: 1.8em;
    margin-top: 20px;
    color: #007bff;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
  }
  
  .container p {
    max-width: 800px;
    margin: 0 auto 20px auto;
  }
  